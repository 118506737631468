<template>
  <div class="content__body">
    <div class="content__title content__title--party">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown class="my-dropdown-toggle"
                    :options="limitOptions"
                    :selected="limitSelected"
                    :closeOnOutsideClick="true"
                    @updateOption="updateLimiter"
          >
          </dropdown>
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> из
          <span>{{ pagination.count }}</span>
        </div>
      </div>
    </div>
    <div class="filters filters--indent filters--between">
      <div class="filters__block">
        <input
          @change="fetchItems(1, limitSelected.name, status)"
          id="apps1"
          type="radio"
          name="apps"
          value="pending"
          v-model="status"
          checked
        >
        <label for="apps1" class="filters__item">На рассмотрении ({{ pending }})</label>
        <input
          @change="fetchItems(1, limitSelected.name, status)"
          id="apps2"
          type="radio"
          name="apps"
          value="accepted"
          v-model="status"
        >
        <label for="apps2" class="filters__item">Принято ({{ accepted }})</label>
        <input
          @change="fetchItems(1, limitSelected.name, status)"
          id="apps3"
          type="radio"
          name="apps"
          value="rejected"
          v-model="status"
        >
        <label for="apps3" class="filters__item">Отклонено ({{ rejected }})</label>
        <input
          @change="fetchItems(1, limitSelected.name, status)"
          id="apps4"
          type="radio"
          name="apps"
          value="reserve"
          v-model="status"
        >
        <label for="apps4" class="filters__item">Резерв ({{ reserve }})</label>
        <input
          @change="fetchItems(1, limitSelected.name, status)"
          id="apps5"
          type="radio"
          name="apps"
          value="rework"
          v-model="status"
        >
        <label for="apps5" class="filters__item">На доработке ({{ rework }})</label>
        <input
          @change="fetchItems(1, limitSelected.name, status)"
          id="apps6"
          type="radio"
          name="apps"
          value=""
          v-model="status"
        >
        <label for="apps6" class="filters__item">Все</label>
      </div>
      <div class="filters__search">
        <v-select
          @search="onSearch"
          :get-option-label="getLabel"
          noDrop
          placeholder="ID заявки / ФИО"
          class="select"
        >
        </v-select>
      </div>
    </div>
    <v-client-table
      @row-click="onRowClick"
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="userListTable"
      class="table-default table-default--dynamic table-default--minw"
    >
      <div slot="user.crop_url" slot-scope="props" class="table-default__logo">
        <img v-if="props.row.user.crop_url" :src="props.row.user.crop_url" alt="Photo">
        <img v-else src="@/assets/img/applications/app-slug.svg" alt="Slug">
      </div>
      <div slot="user.dob" slot-scope="props" class="table-default__logo">
        {{ props.row.user.dob }} (возраст: {{ props.row.user.age }})
      </div>
      <div slot="updated_at" slot-scope="props" class="table-default__logo">
        {{ props.row.updated_at || '-' }}
      </div>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns'
import {debounce} from 'lodash'

export default {
  name: 'ApplicationList',
  components: {
    dropdown,
  },
  data() {
    return {
      table: {
        items: [],
        columns: ['id', 'user.id', 'user.crop_url', 'user.full_name', 'user.dob', 'created_at', 'updated_at'],
        options: {
          headings: {
            'id': 'ID заявки',
            'user.id': 'ID пользователя',
            'user.crop_url': 'Фото',
            'user.full_name': 'ФИО',
            'user.dob': 'Дата рождения',
            'created_at': 'Создана',
            'updated_at': 'Обновлена',
          },
          pagination: {show: false},
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status}`,
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
        prevLink: '',
        nextLink: ''
      },
      limitSelected: {name: 20},
      limitOptions: [
        {name: 20},
        {name: 40},
        {name: 60},
        {name: 80},
        {name: 100},
      ],
      status: '',
      pending: 0,
      rejected: 0,
      accepted: 0,
      reserve: 0,
      rework: 0,
      once: false,
    }
  },
  mounted() {
    this.limitSelected.name = 20
    this.fetchItems(1, this.limitSelected.name)
  },
  methods: {
    fetchItems(page = 1, page_size, status = '', search = '') {
      this.pagination.page = page
      this.$store.dispatch('events/applications/GET_LIST', { event_id: this.$route.params.id, shift_id: this.$route.params.dir, page, page_size, status, search })
        .then(response => {
          this.table.items = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
          if (!this.once) {
            this.$store.dispatch('events/applications/GET_COUNTS', { event_id: this.$route.params.id, shift_id: this.$route.params.dir })
              .then(res => {
                this.pending = res.data.pending
                this.rejected = res.data.rejected
                this.accepted = res.data.accepted
                this.reserve = res.data.reserve
                this.rework = res.data.rework
              })
          }
          this.once = true
        })
    },
    onRowClick(row) {
      this.$router.push({ name: 'application-detail', params: { app: row.row.id } })
    },
    updateLimiter(data) {
      this.limitSelected.name = data.name
      this.$refs.userListTable.setLimit(data.name)
      this.fetchItems(this.pagination.page, this.limitSelected.name, this.status)
    },
    clickPaginationCallback(page) {
      this.fetchItems(page, this.limitSelected.name, this.status)
    },
    returnStart() {
      return (this.pagination.page * this.table.items.length) - (this.table.items.length - 1)
    },
    returnEnd() {
      return this.returnStart() + this.table.items.length - 1
    },
    onSearch(search, loading) {
      loading(true)
      this.searchDelivery(loading, search, this)
    },
    searchDelivery: debounce((loading, search, vm) => {
      vm.fetchItems(1, vm.limitSelected.name, vm.status, search)
      loading(false)
    }, 350),
    getLabel(option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          )
        }
        return option[this.label]
      }
      return option
    },
  }
}
</script>